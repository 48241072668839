import Modal from '@components/Modal/Modal';
import styles from './ModalEditCreateCrud.module.scss';
import { useTranslation } from 'react-i18next';
import withCrudMetadata from '../withCrudMetadata';
import { CrudMetadata, Entity, EntityItem } from '../types';
import CrudEditCreateForm from './CrudEditCreateForm';


const ModalEditCreateCrud = withCrudMetadata(({
  formType,
  entityItem,
  entity,
  metadata,
  onSaved,
  onRequestClose,
}: {
  formType: 'edit' | 'create';
  entity: Entity;
  metadata: CrudMetadata;
  entityItem?: EntityItem;
  onSaved?: () => void | Promise<void>;
  onRequestClose: () => void;
}) => {

  const { t } = useTranslation();
  const layout = "default";

  return (
    <Modal
      title={(formType === 'edit' ? t('edit') : t('create')) + ' ' + entity.label}
      containerProps={{
        style: {
          maxWidth: 1200,
          minWidth: 500,
        },
        className: `${styles.modalConfirmContainer} ${layout ? styles[layout] : ""}`
      }}
      contentContainerProps={{
        className: styles.modalContentContainer
      }}

      onRequestClose={onRequestClose}
      layout={layout}
      style={{
        content: {
          overflow: "visible",
        }
      }}
    >
      <div className={styles.formContainer}>
        <CrudEditCreateForm
          formType={formType}
          entity={entity}
          entityItem={entityItem}
          metadata={metadata}
          onSaved={async () => {
            onRequestClose();
            onSaved && await onSaved();
          }}
        />
      </div>
    </Modal>
  )
});

export default ModalEditCreateCrud;