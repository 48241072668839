import styles from './ListSearchResults.module.scss';

import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import { useTranslation } from 'react-i18next';
import React from 'react';
import { CrudMetadata, EntityItem } from '../../../types';
import Button from '@components/Button/Button';
import { IconChevron } from '@assets/icons/icons';
import { useNavigate } from 'react-router-dom';


function TableTd({
  children,
  isLoading,
  rowSpan,
  className,
  containerClassName,
  testid,
}: {
  children?: React.ReactNode;
  isLoading?: boolean;
  rowSpan?: number;
  className?: string;
  containerClassName?: string;
  testid?: string;
}) {
  return (
    <td rowSpan={rowSpan} className={className} data-testid={testid}>
      <div
        className={containerClassName}
        style={{ visibility: isLoading ? 'hidden': 'visible'}}>{children}</div>
      {isLoading && (<div className={styles.skeletonContainer}>
        <Skeleton
          width={'100%'}
          height={10}
          containerClassName={styles.skeleton}
        />
      </div>)}
    </td>
  )
}

export default function ListSearchResults({
  metadata,
  isLoading,
  results = [],
  sortedBy,
  sortedDirection,
  onClickSort,
  theadClassName,
  containerProps: { className, ...containerProps } = {},
  onClickAction,
}: {
  metadata: CrudMetadata;
  isLoading?: boolean;
  results?: Array<EntityItem>;
  sortedBy?: string;
  sortedDirection?: 'asc' | 'desc';
  onClickSort?: (fieldId: string) => void;
  theadClassName?: string;
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
  onClickAction?: (actionKey: string, entity: EntityItem) => void;
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const hasResults = results.length > 0;
  let hasActions = false;
  results.some(result => {
    hasActions = !!Object.keys(result.actions || {}).filter(key => key === "update" || key === "delete").length;
    return hasActions;
  });

  let hasNavs = false;
  results.some(result => {
    hasNavs = !!result.navs?.length;
    return hasNavs;
  });

  const columnsCount = metadata.listView.columns.length + ((hasActions || hasNavs) ? 1 : 0)

  return (
    <div className={[styles.container, className].join(" ")} {...containerProps}>
      <table border={0} style={{}}>
        <thead className={`${theadClassName}`}>
          <tr>
            {metadata.listView.columns.map(col => (
              col.sortable ? (
              <th
                className={styles.sortable}
                onClick={() => onClickSort && onClickSort(col.id)}
                key={col.id}
              >
                <div className={styles.sortableContainer}>
                  {metadata.fields[col.id]?.label}
                  <div className={styles.sortIcon}>
                    <IconChevron className={`${styles.chevronUp} ${(sortedBy === col.id && sortedDirection === 'asc') ? styles.active : null}`} />
                    <IconChevron className={`${styles.chevronDown} ${(sortedBy === col.id && sortedDirection === 'desc') ? styles.active : null}`} />
                  </div>
                </div>
              </th>
              ) : (
              <th key={col.id}>{metadata.fields[col.id]?.label}</th>
              )
            ))}
            {(hasActions || hasNavs) && (<th>{t('actions')}</th>)}
          </tr>
        </thead>

        {(!hasResults) && (
          <tbody className={styles.noResults}>
            <tr>
              {isLoading ? (
                <td colSpan={columnsCount}>
                  <Skeleton />
                </td>
              ) : (
                <td colSpan={columnsCount}>
                  {t('no_results')}
                </td>
              )}
            </tr>
          </tbody>
        )}

        {hasResults && (
          <tbody>
            {results.map((result, j) => {
              return (
                <tr key={j} className={j === results.length - 1 ? styles.lastRow : ''}>

                  {metadata.listView.columns.map(col => (
                    <TableTd isLoading={isLoading} key={col.id}>
                      {(() => {
                        if (!result[col.id]) {
                          return null;
                        }
                        const fieldMetadata = metadata.fields[col.id]
                        switch (fieldMetadata?.type) {
                          case 'datetime':
                            return (new Date(result[col.id])).toLocaleString("fr-FR", {
                              year: "numeric",
                              month: "2-digit",
                              day: "2-digit",
                              hour: "2-digit",
                              minute: "2-digit"
                            });
                          case 'boolean':
                            return result[col.id] ? t('yes') : t('no');
                          case 'number':
                            return result[col.id];
                          case 'string':
                            return result[col.id];
                          case 'select':
                            return fieldMetadata.options?.find(option => option.id === result[col.id])?.label;
                          default:
                            return null;
                        }
                      })()}
                    </TableTd>
                  ))}

                  {(hasActions || hasNavs) && (
                    <TableTd isLoading={isLoading} containerClassName="flex flex-row justify-center gap-4">

                      {(result.navs || []).map(navAction => (
                        <Button
                          label={navAction.label}
                          type="secondary"
                          size='small'
                          onClick={() => {
                            const a = document.createElement('a');
                            a.href = navAction.href;
                            navigate(a.pathname + a.search);
                          }}
                          containerProps={{
                            style: {
                              height: '30px',
                            }
                          }}
                        />
                      ))}

                      {Object.keys(result.actions || {}).filter(key => key === "update" || key === "delete").map(actionKey => (
                        <Button
                          label={t(`${actionKey}`)}
                          type="secondary"
                          size='small'
                          colorType={actionKey === "delete" ? "error" : undefined}
                          onClick={() => {
                            onClickAction && onClickAction(actionKey, result);
                          }}
                          containerProps={{
                            style: {
                              height: '30px',
                              textWrap: 'nowrap',
                            }
                          }}
                        />
                      ))}
                    </TableTd>
                  )}

                </tr>
              )
            })}
          </tbody>
        )}

      </table>
    </div>
  )
}
